<template>
  <!-- You are signed in, do you want to signn out? -->
  <div v-if="status === 'authenticated'">
    <div class="mx-auto max-w-sm overflow-hidden rounded bg-white outline">
      <!-- example subtitle with subtle styling saying "User details"-->
      <div class="bg-blue-900 px-6 py-4 text-xl font-bold text-white">
        Current User
      </div>

      <!-- <img
        class="w-full h-auto"
        src="https://via.placeholder.com/150"
        alt="User profile image"
      /> -->
      <div class="px-6 py-4 text-center">
        <div class="mb-2 text-xl font-bold">
          {{ fullName }}
        </div>
        <div class="mb-2 text-xl font-bold">{{ session?.user?.email }}</div>
        <!-- <p class="text-gray-700 text-base">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus nec
          iaculis mauris.
        </p> -->
      </div>

      <div class="px-6 py-4">
        <button
          class="w-full rounded-md bg-blue-600 px-4 py-2 font-bold text-white shadow-md hover:bg-blue-400 md:px-6 md:py-3 lg:px-8 lg:py-4"
          @click="signOut()"
        >
          Sign Out
        </button>
      </div>
    </div>
  </div>
  <div v-else>
    <button
      class="rounded-md bg-blue-900 px-4 py-2 text-sm font-bold text-white shadow-md hover:bg-blue-700 md:px-6 md:py-2 md:text-base lg:px-8 lg:py-3"
      @click="signIn('sso', { callbackUrl: '/auth' })"
    >
      Sign In
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed, useAuth } from "#imports";
const { signIn, signOut, status, session } = useAuth();
const fullName = computed(
  () =>
    `${session.value?.user?.givenName ?? ""} ${
      session.value?.user?.familyName ?? ""
    }`.trim() || "Unknown Name"
);
</script>
